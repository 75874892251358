<template>
  <nav :aria-label="$t.utilityNavigationLabel" class="text-sm lh-3">
    <client-only>
      <vf-link
        v-if="$feature.showSignInLinkMobileOnTop && !auth.loggedIn"
        class="lg:hidden"
        role="button"
        to="/sign-in"
        variant="quiet"
        @click.prevent.capture="handleSignIn"
      >
        {{ $t.signIn }}
      </vf-link>
    </client-only>
    <vf-location-selector @click="handleClick($t.locationSelector)" />
    <template v-for="({ type, to, title }, key) in links" :key>
      <base-button
        v-if="type === 'chat' && $feature.enableChat"
        class="hover:underlined"
        data-chat="utility-nav"
        @click="handleChat(title)"
      >
        {{ title }}
      </base-button>
      <template v-else-if="type === 'loyalty' && !user.isWranxOrIpaProfile">
        <vf-link
          :to="auth.loggedIn ? '/account/loyalty' : '/xplr-pass'"
          variant="quiet"
          @click="handleClick(auth.loggedIn ? $t.loyaltyUtilityNavLabel : $t.loyaltyUtilityNavGuestLabel)"
        >
          {{ auth.loggedIn ? $t.loyaltyUtilityNavLabel : $t.loyaltyUtilityNavGuestLabel }}
        </vf-link>
      </template>
      <vf-link v-else-if="type !== 'chat' && type !== 'loyalty'" :to variant="quiet" @click="handleClick(title)">
        {{ title }}
      </vf-link>
    </template>
    <vf-link
      to="/gift-card-page"
      variant="quiet"
      @click="handleClick($t.giftCardUtilityNavigation)"
    >
      {{ $t.giftCardUtilityNavigation }}
    </vf-link>
    <vf-link
      to="/help"
      variant="quiet"
      @click="handleClick($t.help)"
    >
      {{ $t.help }}
    </vf-link>
    <client-only>
      <template v-if="auth.loggedIn">
        <vf-link to="/account" variant="quiet" @click="handleClick($t.myAccount)">
          {{ $t.myAccount }}
        </vf-link>
        <base-button class="hover:underlined" @click="handleSignOut">
          {{ $t.signOut }}
        </base-button>
      </template>
      <template v-else>
        <vf-link
          :class="{ '<lg:hidden': $feature.showSignInLinkMobileOnTop }"
          role="button"
          to="/sign-in"
          variant="quiet"
          @click.prevent.capture="handleSignIn"
        >
          {{ $t.signIn }}
        </vf-link>
      </template>
    </client-only>
    <loyalty-rewards-preview-tile
      v-if="showLoyaltyRewardsInUtilityNav && user.isLoyaltyMember && !user.isWranxOrIpaProfile"
      class="mx-2 <lg:hidden"
      close-on-leave
      gtm-title="loyalty"
      open-on-hover
      to="/account/loyalty"
    />
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationLink } from '#types/navigation'

const { links } = defineProps<{
  links: NavigationLink[]
}>()

const emit = defineEmits<{
  /**
   * Emits when a navigation item is clicked
   */
  click: []
}>()

const auth = useAuthStore()
const { DialogSignIn } = useDialogsStore()
const { $chat, $feature, $gtm, $t } = useNuxtApp()
const { showLoyaltyRewardsInUtilityNav } = useFeatureFlags()
const user = useUserStore()

const handleClick = (title: string) => {
  emit('click')
  $gtm.push('topNav.onClickUtilityLink', capitalize(title))
}

const handleChat = (title: string) => {
  handleClick(title)
  $chat.open()
}

const handleSignIn = async () => {
  handleClick($t.signIn)
  // Workaround until the bug in radix-vue is fixed
  // https://github.com/radix-vue/radix-vue/issues/539
  await nextTick()
  DialogSignIn.open({
    formLocation: 'modal:single:header:none'
  })
}

const handleSignOut = () => {
  handleClick($t.signOut)
  auth.logout()
}
</script>
