<template>
  <base-popover
    v-show="auth.loggedIn && user.isLoyaltyMember"
    ref="rewardsPreviewTileRef"
    v-slot="{ close, open, opened, toggle }"
    :close-on-leave="closeOnLeave"
    placement="bottom-end"
  >
    <vf-button
      ref="rewardsPreviewTileButtonRef"
      :aria-describedby="id"
      :aria-expanded="opened"
      aria-haspopup
      :class="brandClasses.rewardsPreviewTileButton"
      size="tiny"
      :to
      variant="primary-inverse"
      @blur="closeOnLeave ? close($event) : undefined"
      @click="to ? handleClick(gtmTitle!) : toggle($event)"
      @focus="openOnHover ? open($event) : undefined"
      @hover="openOnHover ? open($event) : undefined"
      @keydown.enter="to ? handleClick(gtmTitle!) : toggle($event)"
      @keydown.esc="close($event)"
      @keydown.space="to ? handleClick(gtmTitle!) : toggle($event)"
      @mouseenter="openOnHover ? open($event) : undefined"
    >
      {{ loyaltyMessages.rewardsGreeting }}
      <template v-if="user.isLoyaltyMember && loyalty.rewardsAvailable?.length">
        ({{ loyalty.rewardsAvailable?.length }})
      </template>
    </vf-button>
    <base-popover-content
      :id
      :class="brandClasses.rewardsPreviewTilePopoverContent"
      enter-active-class="transform ease-out duration"
      enter-from-class="op-0 -translate-y-5"
      enter-to-class="translate-y-0 op-100"
      leave-active-class="transform ease-in duration"
      leave-from-class="translate-y-0 op-100"
      leave-to-class="op-0 -translate-y-5"
      role="tooltip"
      style="transition: left 0ms"
      @keydown.esc="close($event)"
    >
      <div
        :class="brandClasses.rewardsPreviewTilePopoverContentInner"
        data-test-id="rewards-info-box"
        style="border-top-right-radius: 0; margin-top: -1px; max-width: 22.44rem"
      >
        <div class="text-md">
          {{ loyaltyMessages.rewardsGreeting }}
        </div>
        <div class="mt-4 flex">
          <vf-icon class="mr-4" size="xl" :src="logo" />
          <div>
            <p class="fw-medium lh-none">
              {{ loyaltyMessages.title }}
            </p>
            <p
              class="mt-2 ws-pre-wrap lh-tight"
              :class="loyalty.rewardsAvailable?.length ? 'text-sm' : 'text-xs'"
              :style="loyalty.rewardsAvailable?.length ? 'width: auto' : 'width: 16.94rem'"
            >
              {{ loyaltyMessages.text }}
            </p>
          </div>
        </div>
      </div>
    </base-popover-content>
  </base-popover>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    closeOnLeave?: boolean
    openOnHover?: boolean
    to?: string
    gtmTitle?: string
  }>(),
  {
    closeOnLeave: false,
    openOnHover: false
  }
)

const emit = defineEmits<{
  /**
   * Emits when a navigation item is clicked
   */
  click: []
}>()

const { brandClasses, logo } = useAppConfig().components.vf.loyalty
const auth = useAuthStore()
const loyalty = useLoyaltyStore()
const { getLoyaltyMessages } = useLoyalty()
const user = useUserStore()
const id = useId()
const { $gtm } = useNuxtApp()
const rewardsPreviewTileRef = ref()
const rewardsPreviewTileButtonRef = ref()

const loyaltyMessages = computed(() => getLoyaltyMessages())

const handleClick = (title: string) => {
  emit('click')
  $gtm.push('topNav.onClickUtilityLink', capitalize(title))
}

watch(() => auth.loggedIn, () => {
  if (auth.loggedIn && !loyalty?.vouchers) loyalty.getVouchers()
}, { immediate: true })
</script>
